<template>
  <v-card
    class="payment-card-container"
  >
    <v-card-text
      class="px-13 py-9 d-flex flex-column align-center justify-center"
    >
      <v-progress-circular
        v-if="!userStore.org!.ownerEmail"
        indeterminate
        color="#111111"
      />
      <template
        v-else-if="isCreditsLimitReached"
      >
        <v-btn
          class="close-btn"
          @click.prevent="emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div
          class="title-row"
        >
          <div class="title-row-left">
            <p class="cta-text pb-2">
              Unlock more generation credits on a paid plan
            </p>
            <p
              class="cta-description"
            >
              You've used all your free image credits. To continue generating new images, upgrade to one of our premium plans.
            </p>
          </div>
          <div class="title-row-right">
            <div
              class="d-flex align-center annual-switch"
            >
              <div
                class="month"
                :class="{ active: !yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = false"
              >
                Monthly
              </div>
              <div
                class="year"
                :class="{ active: yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = true"
              >
                Annual
              </div>
            </div>

            <img
              class="pt-3"
              src="@/assets/stripe.svg"
              alt="stripe"
            >
          </div>
        </div>

        <pricing-option
          :yearly-billing-period="yearlyBillingPeriod"
          :tier="userStore.org!.tier"
          :user-email="userStore.org!.ownerEmail"
          :trial-disabled="isTrialDisabled"
        />
      </template>
      <template
        v-else-if="isTrialDisabled"
      >
        <v-btn
          class="close-btn"
          @click.prevent="emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div
          class="title-row"
        >
          <div
            class="title-row-left"
          >
            <p
              class="cta-text pb-2"
            >
              Unlock Full Access to <span class="pink-text">Katalist</span> {{ userStore.org!.tier === 'Individual' ? 'Business' : 'Pro' }}
            </p>
            <p
              v-if="['trial_ended', 'trial_past_due'].includes(userStore.org!.subscription_status)"
              class="cta-description"
            >
              Your trial has ended. Choose a plan to keep creating with Katalist {{ userStore.org!.tier === 'Individual' ? 'Business' : 'Pro' }}.
            </p>
            <p
              v-if="userStore.org!.tier === 'Free'"
              class="cta-description"
            >
              Upgrade to access all premium features and boost your creative experience.
            </p>
            <p
              v-else
              class="cta-description"
            >
              Your subscription couldn't renew. Upgrade to regain full access.
            </p>
          </div>
          <div class="title-row-right">
            <div
              class="d-flex align-center annual-switch"
            >
              <div
                class="month"
                :class="{ active: !yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = false"
              >
                Monthly
              </div>
              <div
                class="year"
                :class="{ active: yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = true"
              >
                Annual
              </div>
            </div>

            <img
              class="pt-3"
              src="@/assets/stripe.svg"
              alt="stripe"
            >
          </div>
        </div>

        <pricing-option
          :yearly-billing-period="yearlyBillingPeriod"
          :tier="userStore.org!.tier"
          :user-email="userStore.org!.ownerEmail"
          :trial-disabled="isTrialDisabled"
        />
      </template>

      <template v-else>
        <v-btn
          v-if="!route.query.trial"
          class="close-btn"
          @click.prevent="emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div
          class="title-row"
        >
          <div
            class="title-row-left"
          >
            <p
              class="cta-text pb-2"
            >
              Try <span class="pink-text">Katalist</span> {{ userStore.org!.tier === 'Individual' ? 'Business' : 'Pro' }} for free for 7 days
            </p>

            <p
              class="cta-description"
            >
              Experience the full power of Katalist{{ userStore.org!.tier === 'Individual' ? ' Business' : '' }}. We'll send you a reminder email 24 hours before your trial ends.
            </p>
          </div>
          <div class="title-row-right">
            <div
              class="d-flex align-center annual-switch"
            >
              <div
                class="month"
                :class="{ active: !yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = false"
              >
                Monthly
              </div>
              <div
                class="year"
                :class="{ active: yearlyBillingPeriod}"
                @click="yearlyBillingPeriod = true"
              >
                Annual
              </div>
            </div>

            <img
              class="pt-3"
              src="@/assets/stripe.svg"
              alt="stripe"
            >
          </div>
        </div>

        <v-alert
          v-if="userStore.org!.tier === 'Individual' && userStore.org!.available_seats === 0"
          style="margin-bottom: 2em; margin-top: 2em; box-shadow: 5px 5px #FF9ECF; width: 800px"
          title="Upgrade To Collaborate with Your Team in Real-Time!"
          text="Unlock the full potential of your projects with Katalist's Business plan.
            Enjoy real-time collaboration where your team can work together on the same project simultaneously."
          color="black"
        />

        <pricing-option
          :yearly-billing-period="yearlyBillingPeriod"
          :tier="userStore.org!.tier"
          :user-email="userStore.org!.ownerEmail"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import PricingOption from '@/components/PricingOption.vue'
import { ref, onMounted, computed } from 'vue'
import tracking from '@/services/tracking'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'

const yearlyBillingPeriod = ref(false)

const isCreditsLimitReached = computed(() => userStore.org?.tier === 'Free' && userStore.unspentCredits === 0)

const isTrialDisabled = computed(() => ['past_due', 'trial_past_due', 'ended', 'trial_ended', 'trialing'].includes(userStore.org!.subscription_status) || userStore.org?.tier === 'Free')
const route = useRoute()
const emit = defineEmits(['close'])

const userStore = useUserStore()

onMounted(async () => {
  tracking.page('Pricing')
  tracking.track('Pricing Modal Opened')
})

const switchPosition = computed(() => {
  if (userStore.org!.tier === 'Individual' || userStore.org!.tier === 'Essential') {
    return '0'
  } else {
    return 'calc(50% - 150px)'
  }
})
</script>

<style lang="scss" scoped>
@import "@/styles/settings";

.benefit-chip {
  border-radius: 4px;
  padding: 4px 8px;
  border-color: #111;
  background-color: #FF9ECF;
  border-width: 1px;
  border-style: solid;
  margin: 4px;
  color: #111;
  font-size: 12px;
  font-family: 'Fold Grotesque Pro Medium', sans-serif;
}

.cta-text {
  font: normal normal bold 30px/42px Mulish-Bold, sans-serif;
  letter-spacing: 0.25px;
  color: #FFF;
  text-align: center;

  @include xl {
    text-align: left;
  }
}

.cta-description {
  font: normal normal normal 16px/22px Mulish-Regular, sans-serif;
  letter-spacing: 0;
  color: #FFF;
  text-align: center;

  @include xl {
    text-align: left;
    margin-right: 20px;
  }
}

.subhead-text {
  color: #111;
  font-size: 1rem;
  font-family: 'Fold Grotesque Pro Medium', sans-serif;
  font-weight: 400;
}

.logout {
  color: #111;
  font-size: 12px;
  font-family: 'Fold Grotesque Pro', sans-serif;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  margin-top: 16px;
  text-decoration: underline;
  position:absolute;
  right: 0;
}

.chips {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title-row {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: space-between;

  @include xl {
    flex-direction: row;
    align-items: start;
    gap:initial;
  }
}

.title-row-left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @include xl {
    align-items: start;
  }
}

.title-row-right{
  text-align: center;

  @include xl {
    text-align: right;
  }
}

.annual-switch {
  right: v-bind(switchPosition);
  align-self: center;
  cursor: pointer;
  padding:3px;
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.month, .year{
  padding: 6px 12px;
  font: normal normal 600 14px/20px Mulish-SemiBold, sans-serif;
  letter-spacing: 0.12px;
  color: #000;
  opacity: 0.5;
}

.active {
  opacity: 1;
  border-radius: 4px;
  background-color: #F6C86B;
}

.payment-card-container{
  background-color: #000;
  min-width: 400px;
  min-height: 200px;
  margin-top: 30px;

  .payment-card{
    padding: 35px 50px;
  }
}

.pink-text{
  color:#F2A1CD;
}

.close-btn{
  position: absolute;
  right: -4px;
  top: 0;
  background: none;
}
</style>
